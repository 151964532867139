<template>
  <div>
      地址输入错误或您的权限不足，请确认后重新尝试。
      <span @click="exitLogin" style="text-decoration: underline;color: #03A9F4;cursor: pointer">去重新登录</span>
  </div>

</template>

<script>
export default {
  methods:{
    jumpIndex(){
      this.$router.go(-1)
    },
    //退出登录
    exitLogin(){
      // localStorage.removeItem('user_id')
      localStorage.removeItem('info')
      localStorage.removeItem("user_id")
      localStorage.removeItem("user_token")
      this.$router.go(-1)
    },
  }
}
</script>
